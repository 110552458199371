import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

// Components
import SecondaryLanding from "../../../components/layouts/secondary-landing";
import SEO from "../../../components/seo/seo";
import Breadcrumb from "../../../components/breadcrumb/breadcrumb";
import Contactbar from "../../../components/contact-info/contact-info";
import HeroChevron from "../../../components/hero/hero-chevron";
import getHeroImgVariables from "../../../helpers/getHeroImgVariables";

const RetirementAccounts = () => {
  const imgData = useStaticQuery(graphql`
    {
      imgVariableXXL: file(
        relativePath: { eq: "hero/personal-banking/retirement-ira-accounts/hero-retirement-02-XXL.jpg" }
      ) {
        ...heroChevronImageFragmentXXL
      }
      imgVariableXL: file(
        relativePath: { eq: "hero/personal-banking/retirement-ira-accounts/hero-retirement-02-XL.jpg" }
      ) {
        ...heroChevronImageFragmentXL
      }
      imgVariableLG: file(
        relativePath: { eq: "hero/personal-banking/retirement-ira-accounts/hero-retirement-02-LG.jpg" }
      ) {
        ...heroChevronImageFragmentLG
      }
      imgVariableMD: file(
        relativePath: { eq: "hero/personal-banking/retirement-ira-accounts/hero-retirement-02-MD.jpg" }
      ) {
        ...heroChevronImageFragmentMD
      }
      imgVariableSM: file(
        relativePath: { eq: "hero/personal-banking/retirement-ira-accounts/hero-retirement-02-SM.jpg" }
      ) {
        ...heroChevronImageFragmentSM
      }
      imgVariableXS: file(
        relativePath: { eq: "hero/personal-banking/retirement-ira-accounts/hero-retirement-02-XS.jpg" }
      ) {
        ...heroChevronImageFragmentXS
      }
      imgVariableXXS: file(
        relativePath: { eq: "hero/personal-banking/retirement-ira-accounts/hero-retirement-02-XXS.jpg" }
      ) {
        ...heroChevronImageFragmentXXS
      }
    }
  `);

  const title = "Cuentas de ahorro personales para la jubilación";

  const seoData = {
    title,
    meta: [
      {
        name: "application-name",
        content: "WaFd Bank public website"
      },
      {
        name: "title",
        property: "og:title",
        content: title
      },
      {
        name: "description",
        property: "og:description",
        content:
          "Ahorrar para la jubilación es una de las cosas más importantes que puede hacer para asegurar su futuro. Obtenga más información y comience hoy mismo con WaFd Bank."
      },
      {
        property: "og:url",
        content: "https://www.wafdbank.com/es/banca-personal/jubilacion-ira"
      },
      {
        property: "og:type",
        content: "website"
      },
      {
        property: "og:site_name",
        content: "wafdbank"
      },
      {
        property: "og:image",
        content: "https://www.wafdbank.com/images/adsearch/thumb-ira-03-250.jpg"
      }
    ]
  };

  const heroChevronData = {
    id: "ira-hero",
    ...getHeroImgVariables(imgData),
    altText: "Little girl being taught how to ride a bicycle by her grandfather at the park",
    heroBody: {
      components: [
        {
          id: 1,
          heading: {
            class: "text-white",
            text: "Empieza a Ahorrar Hoy, Tu Futuro Yo te lo Agradecerá"
          }
        },
        {
          id: 2,
          button: {
            id: "ira-hero-cta",
            text: "Encuentra una Sucursal Local",
            url: "/es/sucursales",
            class: "btn-white"
          }
        }
      ]
    }
  };

  const breadcrumbData = [
    {
      id: 1,
      url: "/es",
      title: "Banca personal"
    },
    {
      id: 2,
      active: true,
      title: "Jubilación"
    }
  ];

  return (
    <SecondaryLanding>
      <HeroChevron {...heroChevronData} />
      <SEO {...seoData} />
      <Breadcrumb data={breadcrumbData} />
      <section className="container">
        <h1>Jubilación</h1>
        <h3>
          En algún momento, todos dejaremos de trabajar y nos retiraremos. Si bien eso podría parecer lejano, nunca es
          demasiado pronto para comenzar a planificar su futuro financiero. Lamentablemente, la mayoría de los
          estadounidenses no están tan preparados como debieran para el momento del retiro. Si está pensando en comenzar
          a ahorrar, una cuenta de fondo para retiro, o IRA, puede ser adecuada para usted.<sup>*</sup>
        </h3>
        <h2 className="text-success">Cercanía al retiro</h2>
        <div className="row">
          <div className="col-md-6">
            <p>
              Usted se ha esforzado mucho para ganar lo que tiene. Queremos ayudarle a aprovecharlo al máximo. Si la
              próxima etapa de su vida es disfrutar del retiro, tenemos algunas herramientas para ayudarle. Una cuenta
              de fondo para retiro, o IRA, está sujeta a ventajas tributarias especiales, que le ayudarán a generar
              ahorros de manera más eficaz.* Una vez que usted abre una cuenta IRA, puede invertir los fondos en
              certificados de depósito, fondos del mercado monetario, fondos mutuos o acciones y bonos individuales. Los
              dos tipos más comunes de cuentas IRA son Roth y tradicional.
            </p>
            <StaticImage
              src="../../../images/thumbnail-retirement-nearing-090523.jpg"
              alt="Mature couple laughing and gardening."
              quality="100"
              placeholder="blurred"
            />
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <h5>¿Qué es una IRA?</h5>
            <p>
              Una IRA es una cuenta de inversión con beneficios fiscales que tiene como objetivo ayudarlo a ahorrar para
              la jubilación. Dependiendo del tipo de plan IRA y las inversiones dentro de ese plan, los ingresos por
              intereses, dividendos y ganancias de capital se componen cada año sin ser gravados mientras los fondos
              permanecen dentro de un plan IRA.
            </p>
            <h5>Me gustaría abrir una cuenta IRA. ¿Qué alternativas me ofrecen?</h5>
            <p>
              Hay tres distintos tipos de cuentas IRA. Cada una viene con su propio conjunto de reglas y restricciones.
              Los dos tipos más comunes son Roth IRA y cuenta tradicional IRA. La principal diferencia entre una cuenta
              Roth IRA y una cuenta tradicional IRA radica en la forma en que tributan. Con una cuenta Roth IRA, usted
              paga impuestos cuando pone dinero en la cuenta y no tiene que pagar impuestos cuando retira el dinero al
              momento de retirarse. El dinero invertido en las cuentas tradicionales IRA no se grava cuando se invierte,
              sino que está sujeto a impuestos cuando se retiran los fondos.
            </p>
            <Link to="/es/sucursales" className="btn btn-primary" id="locations-link-what-is-ira-section">
              Encuentra una Sucursal Local
            </Link>
          </div>
        </div>
      </section>
      <section className="container py-0">
        <table className="table table-bordered d-sm-table d-none">
          <thead className="bg-info text-white">
            <tr id="es-ira-table-title">
              <th className="w-25">Analizar las opciones de cuentas IRA</th>
              <th>Roth IRA</th>
              <th>Tradicional IRA</th>
            </tr>
          </thead>
          <tbody>
            <tr id="es-ira-table-can-i-contribute-row">
              <td>
                <strong>¿Puedo hacer mis propios aportes?</strong>
              </td>
              <td>
                Usted puede hacer aportes propios si recibe remuneraciones (o presenta una declaración tributaria en
                conjunto con su cónyuge remunerado) y su ingreso bruto ajustado modificado (MAGI, por sus siglas en
                inglés) es igual o inferior a los límites definidos. En el{" "}
                <a
                  id="irs-individual-retirement-arrangements-iras-external-link"
                  rel="noopener noreferrer"
                  href="https://www.irs.gov/retirement-plans/individual-retirement-arrangements-iras"
                  target="_blank"
                >
                  sitio web de IRS
                </a>{" "}
                encontrará más información acerca de los límites de MAGI.
              </td>
              <td>
                Usted tiene derecho a hacer aportes propios a cualquier edad y recibe remuneraciones (o si presenta una
                declaración tributaria en conjunto con su cónyuge remunerado).
              </td>
            </tr>
            <tr id="es-ira-table-tax-deduction-row">
              <td>
                <strong>¿Puedo deducir mi aporte de mis impuestos?</strong>
              </td>
              <td>No. Los aportes a la cuenta Roth IRA no son deducibles de impuestos.</td>
              <td>
                Depende de su estado civil y categoría tributaria, su MAGI, y de si usted o su cónyuge participan
                activamente en un plan de retiro patrocinado por su empleador. Si ni usted ni su cónyuge es un
                participante activo, usted tiene derecho a deducir su aporte íntegramente.
              </td>
            </tr>
            <tr id="es-ira-table-benefits-row">
              <td>
                <strong>¿Cuáles son los beneficios?</strong>
              </td>
              <td>
                <ul>
                  <li>
                    Puede que tenga derecho a un crédito tributario al ahorro de hasta $1,000 cuando haga un aporte.
                  </li>
                  <li>
                    Debido a que todos los aportes a la cuenta Roth IRA se deben incluir en la declaración de impuestos,
                    y por lo tanto son gravables, puede sacar sus aportes en cualquier momento, sin deducciones ni
                    multas.
                  </li>
                  <li>
                    Toda ganancia generada con la cuenta IRA tiene tributación diferida (es decir, no paga impuestos por
                    las ganancias sino hasta que retira los fondos).
                  </li>
                  <li>
                    Si cumple con los requisitos de distribución calificada, puede retirar las ganancias libres de
                    impuestos, que es en definitiva la mayor ventaja de tener una cuenta Roth IRA.
                  </li>
                  <li>Nunca tendrá que sacar dinero de su cuenta Roth IRA, no importa la edad que tenga.</li>
                  <li>Pueden aplicar restricciones.</li>
                </ul>
              </td>
              <td>
                <ul>
                  <li>
                    Puede que tenga derecho a un crédito tributario al ahorro de hasta $1,000 cuando haga un aporte.
                  </li>
                  <li>
                    Toda ganancia generada con la cuenta IRA tiene tributación diferida (es decir, no paga impuestos
                    sobre las ganancias sino hasta que retira los fondos).
                  </li>
                  <li>
                    Si los aportes a su cuenta de retiro tradicional IRA son deducibles de impuestos y por lo tanto
                    tienen tributación diferida, no paga los impuestos correspondientes sino hasta que retira el dinero.
                  </li>
                  <li>
                    Todos los montos posteriores a impuestos (aportes no deducibles) dentro de la cuenta IRA se pueden
                    girar sin deducciones ni multas.
                  </li>
                </ul>
              </td>
            </tr>
            <tr id="es-ira-table-required-withdraw-row">
              <td>
                <strong>¿Alguna vez tendré que retirar el dinero?</strong>
              </td>
              <td>
                No. Los propietarios de cuentas Roth IRA nunca deben tomar distribuciones. Sin embargo, cuando usted
                fallezca es posible que sus beneficiarios deban someterse a las distribuciones exigidas.
              </td>
              <td>
                Sí. Los propietarios de cuentas tradicionales IRA deben tomar distribuciones mínimas anuales a
                principios de año cuando cumplan 73 años. Sus beneficiarios también estarán sujetos a las distribuciones
                correspondientes.
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table table-bordered d-sm-none d-table">
          <thead className="bg-info text-white">
            <tr id="es-roth-ira-table-title">
              <th></th>
              <th>Roth IRA</th>
            </tr>
          </thead>
          <tbody>
            <tr id="es-roth-ira-table-can-i-contribute-row">
              <td>
                <strong>¿Puedo hacer mis propios aportes?</strong>
              </td>
              <td>
                Usted puede hacer aportes propios si recibe remuneraciones (o presenta una declaración tributaria en
                conjunto con su cónyuge remunerado) y su ingreso bruto ajustado modificado (MAGI, por sus siglas en
                inglés) es igual o inferior a los límites definidos. En el{" "}
                <a
                  id="irs-individual-retirement-arrangements-iras-external-link"
                  rel="noopener noreferrer"
                  href="https://www.irs.gov/retirement-plans/individual-retirement-arrangements-iras"
                  target="_blank"
                >
                  sitio web de IRS
                </a>{" "}
                encontrará más información acerca de los límites de MAGI.
              </td>
            </tr>
            <tr id="es-roth-ira-table-tax-deduction-row">
              <td>
                <strong>¿Puedo deducir mi aporte de mis impuestos?</strong>
              </td>
              <td>No. Los aportes a la cuenta Roth IRA no son deducibles de impuestos.</td>
            </tr>
            <tr id="es-roth-ira-table-benefits-row">
              <td>
                <strong>¿Cuáles son los beneficios?</strong>
              </td>
              <td>
                <ul>
                  <li>
                    Puede que tenga derecho a un crédito tributario al ahorro de hasta $1,000 cuando haga un aporte.
                  </li>
                  <li>
                    Debido a que todos los aportes a la cuenta Roth IRA se deben incluir en la declaración de impuestos,
                    y por lo tanto son gravables, puede sacar sus aportes en cualquier momento, sin deducciones ni
                    multas.
                  </li>
                  <li>
                    Toda ganancia generada con la cuenta IRA tiene tributación diferida (es decir, no paga impuestos por
                    las ganancias sino hasta que retira los fondos).
                  </li>
                  <li>
                    Si cumple con los requisitos de distribución calificada, puede retirar las ganancias libres de
                    impuestos, que es en definitiva la mayor ventaja de tener una cuenta Roth IRA.
                  </li>
                  <li>Nunca tendrá que sacar dinero de su cuenta Roth IRA, no importa la edad que tenga.</li>
                  <li>Pueden aplicar restricciones.</li>
                </ul>
              </td>
            </tr>
            <tr id="es-roth-ira-table-required-withdraw-row">
              <td>
                <strong>¿Alguna vez tendré que retirar el dinero?</strong>
              </td>
              <td>
                No. Los propietarios de cuentas Roth IRA nunca deben tomar distribuciones. Sin embargo, cuando usted
                fallezca es posible que sus beneficiarios deban someterse a las distribuciones exigidas.
              </td>
            </tr>
          </tbody>
        </table>
        <table className="table table-bordered d-sm-none d-table">
          <thead className="bg-info text-white">
            <tr id="es-traditional-ira-table-title">
              <th></th>
              <th>Tradicional IRA</th>
            </tr>
          </thead>
          <tbody>
            <tr id="es-traditional-ira-table-can-i-contribute-row">
              <td>
                <strong>¿Puedo hacer mis propios aportes?</strong>
              </td>
              <td>
                Usted tiene derecho a hacer aportes propios a cualquier edad y recibe remuneraciones (o si presenta una
                declaración tributaria en conjunto con su cónyuge remunerado).
              </td>
            </tr>
            <tr id="es-traditional-ira-table-tax-deduction-row">
              <td>
                <strong>¿Puedo deducir mi aporte de mis impuestos?</strong>
              </td>
              <td>
                Depende de su estado civil y categoría tributaria, su MAGI, y de si usted o su cónyuge participan
                activamente en un plan de retiro patrocinado por su empleador. Si ni usted ni su cónyuge es un
                participante activo, usted tiene derecho a deducir su aporte íntegramente.
              </td>
            </tr>
            <tr id="es-traditional-ira-table-benefits-row">
              <td>
                <strong>¿Cuáles son los beneficios?</strong>
              </td>
              <td>
                <ul>
                  <li>
                    Puede que tenga derecho a un crédito tributario al ahorro de hasta $1,000 cuando haga un aporte.
                  </li>
                  <li>
                    Toda ganancia generada con la cuenta IRA tiene tributación diferida (es decir, no paga impuestos
                    sobre las ganancias sino hasta que retira los fondos).
                  </li>
                  <li>
                    Si los aportes a su cuenta de retiro tradicional IRA son deducibles de impuestos y por lo tanto
                    tienen tributación diferida, no paga los impuestos correspondientes sino hasta que retira el dinero.
                  </li>
                  <li>
                    Todos los montos posteriores a impuestos (aportes no deducibles) dentro de la cuenta IRA se pueden
                    girar sin deducciones ni multas.
                  </li>
                </ul>
              </td>
            </tr>
            <tr id="es-traditional-ira-table-required-withdraw-row">
              <td>
                <strong>¿Alguna vez tendré que retirar el dinero?</strong>
              </td>
              <td>
                Sí. Los propietarios de cuentas tradicionales IRA deben tomar distribuciones mínimas anuales a
                principios de año cuando cumplan 73 años. Sus beneficiarios también estarán sujetos a las distribuciones
                correspondientes.
              </td>
            </tr>
          </tbody>
        </table>
        <div className="row my-4">
          <div className="col-md-6">
            <h2 className="text-success">Vivir la Jubilación</h2>
            <h4>
              Todo su esfuerzo le ha permitido disfrutar de esta etapa de retiro en su vida. Ahora más que nunca, es
              importante aprovechar al máximo su dinero para que pueda continuar disfrutando lo que se ha ganado.
              Tenemos ideas para ayudarle.* Además, en WaFd Bank, ofrecemos traspasos y transferencias de IRA sin cargo
              alguno.
            </h4>
            <h5>Traspaso a su IRA para un mejor retiro</h5>
            <p>
              Traspasar los montos de un plan de retiro a otro plan de retiro o a una IRA no solo conservará sus
              beneficios fiscales, sino que posiblemente le brindará beneficios adicionales.
            </p>
            <Link to="/es/sucursales" className="btn btn-primary" id="locations-link-living-in-retirement-section">
              Encuentra una Sucursal Local
            </Link>
          </div>
          <div className="col-md-6 mt-4 mt-md-0">
            <StaticImage
              src="../../../images/thumbnail-retirement-living-in-090523.jpg"
              alt="Mother and adult daughter hugging and smiling. "
              quality="100"
              placeholder="blurred"
            />
          </div>
        </div>
        <table className="table table-bordered">
          <tbody>
            <tr id="es-retirement-table-roll-it-out-row">
              <td className="w-25">
                <strong>Cuándo puede hacer el traspaso</strong>
              </td>
              <td className="w-75">
                <p>
                  Los planes de retiro patrocinados por el empleado tienen ldquo;eventos desencadenantesrdquo; que
                  dictan cuándo se puede retirar el dinero de su cuenta, tales como el cumplimiento de la edad de
                  retiro, el término del empleo, discapacidad o fallecimiento.
                </p>
                <p>
                  Después, usted se debe asegurar de que la cantidad que está tomando tenga derecho al traspaso. La
                  mayoría de las distribuciones de los planes de retiro tienen derecho a ser traspasadas a cuentas IRA o
                  a otros planes de retiro elegibles, pero otras no.
                </p>
                <p>Hable con su empleador para informarse sobre sus opciones.</p>
              </td>
            </tr>
            <tr id="es-retirement-table-roll-to-traditional-row">
              <td>
                <strong>¿Cómo puedo traspasar a una cuenta tradicional IRA?</strong>
              </td>
              <td>
                <ul>
                  <li>Planes de retiro calificados, tales como los Planes 401(k)</li>
                  <li>Planes de ahorro para empleados federales</li>
                  <li>Planes 403(b)</li>
                  <li>Planes gubernamentales 457(b)</li>
                  <li>Planes IRA sencillos</li>
                </ul>
              </td>
            </tr>
            <tr id="es-retirement-table-roll-to-roth-row">
              <td>
                <strong>¿Qué puedo traspasar a una cuenta Roth IRA?</strong>
              </td>
              <td>
                <ul>
                  <li>Planes de retiro calificados, tales como los Planes 401(k)</li>
                  <li>Planes de ahorro para empleados federales</li>
                  <li>Planes 403(b)</li>
                  <li>Planes gubernamentales 457(b)</li>
                  <li>Designado</li>
                  <li>Cuentas de planes Roth**</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
        <p className="text-muted text-sm">
          <span id="es-ira-disclaimer-1">
            *Consulte a un profesional tributario para determinar qué opciones cumplen sus objetivos financieros.
          </span>
          <br />
          <span id="es-ira-disclaimer-2">
            **Los montos antes de impuestos traspasados de un plan de retiro a una cuenta Roth IRA se deben incluir en
            la declaración de impuestos el año de la distribución.
          </span>
        </p>
      </section>
      <Contactbar isSpanish={true} />
    </SecondaryLanding>
  );
};

export default RetirementAccounts;
